<template>
  <div class="pa-3" style="position: relative;">
    <LoadingOverlay :show="loading"/>
    <div class="pl-0 font-weight-medium subtitle-1 my-2">{{ $t('flow.nodes.requestTrigger.configuration.title') }}</div>

    <div  v-if="showRedirect" class="pl-0">{{ $t('flow.nodes.requestTrigger.configuration.redirectDescription') }}</div>
    <div  v-else class="pl-0">{{ $t('flow.nodes.requestTrigger.configuration.description') }}</div>

    <BaseLinkView
        :uri="link"
        :buttons="buttons"
        class="my-4"
      />
      <LinkRestrictionsDialog
        v-model="restrictionDialog"
        :link="flow?.trigger"
      />
      <QrCodeDialog
        v-model="qrCodeDialog"
       :uri="absoluteUri"
      />


    <template v-if="showRedirect">
      <div class="pl-0 text-pre-wrap">{{ $t('flow.nodes.requestTrigger.configuration.redirectTips') }}</div>
    </template>
    <template v-else>

    <div class="pl-0 font-weight-medium subtitle-1 my-2">{{ $t('flow.nodes.requestTrigger.configuration.postRequestTitle') }}</div>
    <div class="pl-0">{{ $t('flow.nodes.requestTrigger.configuration.postRequest') }}</div>

    <div class="pl-0 font-weight-medium subtitle-1 my-2">{{ $t('flow.nodes.requestTrigger.configuration.webhookTitle') }}</div>
    <div class="pl-0">{{ $t('flow.nodes.requestTrigger.configuration.webhook') }}</div>
  </template>
  </div>
</template>

<script>
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import BaseLinkView from '@/components/BaseLinkView.vue'
import LinkRestrictionsDialog from '@/components/LinkRestrictionsDialog.vue'
import QrCodeDialog from '@/components/QrCodeDialog.vue'

export default {
  props: {
    flow: null,
  },
  data() {
    return {
      loading: false,
      restrictionDialog: false,
      qrCodeDialog: false
    }
  },
  computed: {
    link() {
      return this.flow?.trigger?.uri
    },
    redirectLink() {
      return this.flow?.trigger?.redirectUri
    },
    absoluteUri() {
      if (this.redirectLink == null) {
        return
      }
      return `${window.location.origin}${this.redirectLink}`
    },
    showRedirect() {
      if (this.flow == null) {
        return false
      }
      return this.flow.hasFlowFormTrigger()
    },
    buttons() {
      return [
        {
          icon: 'mdi-share',
          color: 'primary',
          tooltip: this.$t('forms.shareForm.buttons.open'),
          onClick: this.open,
          testId: 'openSharedFormButton'
        },
        {
          icon: 'mdi-content-copy',
          color: 'primary',
          onClick: this.copyLink,
          tooltip: this.$t('forms.shareForm.buttons.copy'),
          testId: 'copySharedFormLinkButton'
        },
        {
          icon: 'mdi-qrcode',
          color: 'primary',
          onClick: this.showQrCode,
          tooltip: this.$t('forms.shareForm.buttons.qrCode'),
          testId: 'showFormLinkQrCodeButton'
        },
        {
          icon: 'mdi-lock-open-variant-outline',
          color: 'primary',
          onClick: this.showRestrictionsDialog,
          tooltip: this.$t('forms.shareForm.buttons.setRestrictions'),
          testId: 'showFormLinkRestrictionsDialogButton'
        },
      ]
    },
  },
  methods: {
      copyLink() {
        navigator.clipboard.writeText(this.absoluteUri)
      },
      showQrCode() {
        this.qrCodeDialog = true
      },
      open() {
        window.open(this.absoluteUri, '_blank')
      },
      showRestrictionsDialog() {
        this.restrictionDialog = true
      }
  },
  components: {
    LoadingOverlay,
    BaseLinkView,
    LinkRestrictionsDialog,
    QrCodeDialog
  }
}
</script>