<template>

    <v-navigation-drawer v-model="spacesSideNavigation" app floating shrink  width="240px" color="#f5f5f5">
        <div class="d-flex my-6 ml-6 align-center">
            <img width="150px" :src="logo" />
        </div>
        <v-list dense rounded>
            <v-list-item v-for="item in items" :key="item.title" link :to="item.to" exact color="primary">
                <v-list-item-icon class="mr-3">
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <template v-slot:append>
            <v-list dense nav>
                <HelpMenu
        v-model="helpMenu"
        right offset-x
      >
      <template v-slot:activator="{on}">
        <v-list-item
          v-on="on"
          data-testid="helpMenuButton"
          
        >
        <v-list-item-icon class="mr-3">

          <v-badge dot color="error" :value="showUnreadBadge">
            <v-icon>mdi-lifebuoy</v-icon>
          </v-badge>
        </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t('helpMenu.title') }}</v-list-item-title>
        </v-list-item-content>
        </v-list-item>
      </template>
      </HelpMenu>
                <v-list-item v-for="item in appendItems" :key="item.title" link :to="item.to" color="">
                    <v-list-item-icon class="mr-3">
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </template>

    </v-navigation-drawer>

</template>

<script>
import HelpMenu from '@/components/HelpMenu.vue'

export default {
    data() {
        return {
            helpMenu: false,
            items: [
                {
                    title: this.$t('spaces.allSpaces'),
                    icon: 'mdi-view-grid-outline',
                    to: { name: 'Spaces', query: { view: 'all' } }
                },
                {
                    title: this.$t('spaces.sharedWithMe'),
                    icon: 'mdi-account-multiple-outline',
                    to: { name: 'Spaces', query: { view: 'shared' } }
                },
            ],
            appendItems: [
                {
                    title: this.$t('settings.accountSettingsButton'),
                    icon: 'mdi-cog-outline',
                    to: { path: '/settings/profile' }
                }
            ]
        }
    },
    computed: {
        logo() {
            return `${this.$settings.app}/logo.svg`
        },
        spacesSideNavigation: {
            get() {
                return this.$store.state.spacesSideNavigation
            },
            set(newVal) {
                return this.$store.commit('setSpacesSideNavigation', newVal)
            }
        },
        showUnreadBadge() {
            return this.$intercom?.unreadCount != null && this.$intercom?.unreadCount > 0
        },
    },
    components: {
        HelpMenu
    }
}
</script>

<style></style>